import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import AppRouter from './AppRouter.tsx';

const config = {
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  webSocketBaseUrl: process.env.REACT_APP_WEBSOCKET_BASE_URL,
  northspyreProjectsAppUrl: process.env.REACT_APP_NORTHSPYRE_PROJECTS_APP_URL,
};

const App = () => {
  return (
    <Auth0Provider
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: config.auth0Audience,
      }}
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
    >
      <AppRouter />
    </Auth0Provider>
  );
}

export default App;
