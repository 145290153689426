import React from 'react';
import {
    createBrowserRouter, RouterProvider, createRoutesFromElements, Route
} from "react-router-dom";
import Counter from './views/Counter/Counter.jsx';
import Tiff from './views/Tiff/Tiff';
import NSRoute from './components/NSRoute/NSRoute';

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route element={<NSRoute />}>
                <Route path="/" element={<Counter/>} />
                <Route path="martin" element={<div>me gusta shake shack</div>} />
                <Route path="tiff" element={<Tiff />} />
            </Route>
        </>
    )
  );

const AppRouter = () => {
    return (
        <RouterProvider router={router} />
    );
};

export default AppRouter;
