import React from 'react';

export interface ITiffProps {
    age?: number;
}

const Tiff = ({ age }: ITiffProps) => {

    return (
        <>
            <h4>Hello im Tiff</h4>
            <p>{`I am ${age || 29}.`}</p>
        </>
    );
};

export default Tiff;
