import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

const config = {
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  webSocketBaseUrl: process.env.REACT_APP_WEBSOCKET_BASE_URL,
  northspyreProjectsAppUrl: process.env.REACT_APP_NORTHSPYRE_PROJECTS_APP_URL,
};


const Counter = () => {
  const [responseMessage, setResponseMessage] = useState('');
  const [counter, setCounter] = useState(null);
  const { loginWithRedirect, logout, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const initializeWebSocket = async () => {
        try {
          const token = await getAccessTokenSilently({
            authorizationParams: {
              audience: config.auth0Audience,
            }
          });

          // Append the token as a query parameter in the WebSocket URL
          const webSocketUrl = `${config.webSocketBaseUrl}/counter?token=${token}`;
          const ws = new WebSocket(webSocketUrl);

          ws.onopen = () => {
            console.log('Connected to WebSocket server');
          };

          ws.onmessage = (event) => {
            const data = JSON.parse(event.data);
            setCounter(data.count); // Update counter based on WebSocket message
          };

          ws.onerror = (error) => {
            console.error('WebSocket connection error:', error);
          };

          return () => {
            ws.close();
          };
        } catch (error) {
          console.error('Failed to fetch access token:', error);
        }
      };

      initializeWebSocket();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const handleButtonClick = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: config.auth0Audience,
        }
      });

      const response = await fetch(`${config.apiBaseUrl}/increment_counter`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      setCounter(data.count); // Update the counter with the HTTP response
    } catch (error) {
      setResponseMessage('Error: ' + error.message);
    }
  };

  const handleRedirect = () => {
    window.location.href = config.northspyreProjectsAppUrl;
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>Welcome to Northspyre Deal</p>
        {isAuthenticated ? (
          <>
            <Button onClick={handleButtonClick}>Increment Counter</Button>
            <Button onClick={() => logout({ returnTo: window.location.origin })} className="ml-2">Logout</Button>
            <p>Current Counter Value: {counter !== null ? counter : 'Click the button to increment the count!'}</p>
            <Button onClick={handleRedirect}>Go to Northspyre Projects App</Button>
            <div className="my-3">
                <Link to="/tiff" className="d-block">Tiff</Link>
                <Link to="/martin">Martin</Link>
            </div>
          </>
        ) : (
          <button onClick={loginWithRedirect}>Login</button>
        )}
        {responseMessage && <p>{responseMessage}</p>}
      </header>
    </div>
  );
}

export default Counter;
